.galleryRoot {
  display: grid;
  justify-items: center;
  padding-bottom: 2.5rem;
}

.d3 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #f0f0f0;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.wrapper {
  padding-bottom: 2.5rem;
}

.loadingWrapper {
  position: relative;
  top: -44px;
}

.fabProgress {
  color: #4caf50;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  opacity: 0.9;
}

.loadingWrapper img {
  cursor: pointer;   
  border-radius: 200px;
  border: 6px solid#EEEEEE;
  border-radius: 200px;
  transition: stroke-opacity 1s, stroke-width 1s, transform 1s;
  width: 80px;
}

.loadingWrapper img:hover {
  transform: scale(1.2);
}







.slider {
  margin: 50px auto 0px;
  width: 300px
}

.slider > span > span > span {
 width: 42px;
 height: 42px;
 top: -42px;
}