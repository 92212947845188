.root {
  width: 293px;
  height: 293px;
}
.video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
