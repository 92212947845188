ol, ul {
    list-style: none;
}

.root {
    width: 70%;    
}

.root > ul {
    padding: 40px;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    grid-gap: 1rem;
}

.root > ul > li > img {
    position: relative;
    border: 3px solid#EEEEEE;
    border-radius: 200px;
    transition: stroke-opacity 1s, stroke-width 1s, transform 1s;
}

.root > ul > li > img:hover {
    cursor: pointer;   
    transform: scale(1.2);
}

.element {    
    text-align: center;
}