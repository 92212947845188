@media (max-height: 700px) {
    .root {
      display: none;
    }
  }
.root {   
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2.5rem; 
    background: #f2f2f2;
    line-height: 40px;
    min-width: 1173px;
    border-top: 1px solid #e4e4e4;
}

.root .items {
    display: flex;
    align-items: center;
    margin: 0 auto;
    height: inherit;
}
ol {
    padding-inline-start: 20px;
}

li {
    margin-left: 8px;
    user-select: none;
    margin-right: 8px;
}