.root {
  width: 100%;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(293px, 293px));
  grid-gap: 2rem;
  justify-content: center;
} 

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0 10px;
}

.title .txt {
  padding-left: 5px;
}